* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}
.profile {
  text-align: center;
  margin-top: 100px;
}
.pic {
    padding: 10px;
}
.nick {
    padding: 10px;
}
.email {
    padding: 10px;
}
